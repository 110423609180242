/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
$color-base: #4db6ad;
$black-color: #222;
$blue-color: #6dabe4;
$grey-light: #999;
$color-base: #4db6ad;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  @apply bg-gray-950;
  font-family: 'Varela Round', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.mat-typography {
  figure {
    margin: 0;
  }

  a {
    text-decoration: none !important;
  }

  p {
    @apply mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-400;
  }

  h4 {
    @apply m-0;
  }

  h3 {
    @apply m-0 text-2xl font-bold text-primary-100;
  }

  h2 {
    @apply text-h2 mb-2 text-gray-500;
  }

  h1 {
    @apply text-h1 text-gray-400 mb-2;
  }

  ul {
    @apply pl-0 md:pl-8;
  }

  label {
    @apply block text-sm font-semibold leading-6 text-white;
  }

  input {
    transition-duration: 0.25s;
    @apply mt-2.5 block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus-visible:right-2 focus:ring-2 focus:ring-inset focus-visible:ring-inset focus:ring-fuchsia-700 focus-visible:ring-fuchsia-700 sm:text-sm sm:leading-6;
  }

  textarea {
    transition-duration: 0.25s;
    @apply block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-fuchsia-700 sm:text-sm sm:leading-6;
  }
  
  textarea:focus,
  textarea:focus-visible,
  input:focus,
  input:focus-visible {
    transform: scale(1.1);
  }

  input:focus-visible,
  textarea:focus-visible {
    outline: none;
  }

  input.ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched {
    border: 1px solid red !important;
    &:focus {
      @apply focus:ring-0 focus-visible:right-0
    }
  }
  span.error {
    @apply text-red-600;
  }
  input.ng-valid.ng-touched,
    textarea.ng-valid.ng-touched {
      border: 2px solid green;
      &:focus {
        @apply focus:ring-0 focus-visible:right-0
      }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #050911af inset !important;
  -webkit-text-fill-color: white;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9e00a8b1;
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #be1347;
  --mdc-protected-button-label-text-color: #fff;
}